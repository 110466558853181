<template>
  <view-item-setting>
    <template #operation>
      <button-ajax :method="operation.save.click">保存</button-ajax>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-form :loading="table.loading">
          <en-tabs v-model="tabs.active" tab-position="left">
            <en-tab-pane
              v-for="item of table.attributeData"
              :key="item.id?.keyType?.code"
              :label="item.id?.keyType?.message"
              :name="item.id?.keyType?.code"
            >
              <en-scrollbar :height="height" view-class="grid grid-cols-3 gap-x-6">
                <en-form-item v-for="formItem of item.items" :label="formItem.id?.message">
                  <template v-if="formItem.id?.valueType === 'STRING'">
                    <en-input v-model="formItem.value" :disabled="formItem.id.code === 'FLSEPATH'"></en-input>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'TEXT'">
                    <en-input v-model="formItem.value" type="textarea"></en-input>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'PICTURE'">
                    <upload-maintain v-model="formItem.value"></upload-maintain>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'NUMBER'">
                    <en-input v-model="formItem.value"></en-input>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'DATE'">
                    <en-date-picker v-model="formItem.value"></en-date-picker>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'TIME'">
                    <en-date-picker v-model="formItem.value"></en-date-picker>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'DATETIME'">
                    <en-date-picker v-model="formItem.value"></en-date-picker>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'REGION'">
                    <span>Todo</span>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'FLAG'">
                    <select-maintain
                      v-model="formItem.value"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: 'message', value: 'code' }"
                      class="w-full"
                    ></select-maintain>
                  </template>
                  <template v-else-if="formItem.id?.valueType === 'CUSTOMER'">
                    <select-maintain
                      v-model="formItem.value"
                      :ajax="{ action: 'GET /enocloud/common/customer', params: (params, value) => (params.payload = { name: value, type: 'B' }) }"
                      :props="{ label: 'name', value: '' }"
                      value-key="id"
                      remote
                      class="w-full"
                    ></select-maintain>
                  </template>
                  <template v-else>
                    <select-maintain
                      v-model="formItem.value"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = table.lookup.paths(formItem)) }"
                      :props="{ label: 'message', value: 'code' }"
                      class="w-full"
                    ></select-maintain>
                  </template>

                  <template v-if="formItem.id?.description">
                    <span class="text-xs text-primary">{{ formItem.id?.description }}</span>
                  </template>

                  <template v-if="formItem.id?.code === 'ACTCSTCSA'">
                    <span class="text-xs text-red-500">说明：活跃期客户设置需要同时满足未到店不可超出多少天数、到店次数、消费金额三个条件</span>
                  </template>

                  <template v-if="formItem.id?.code === 'LSCSTDPTD'">
                    <span class="text-xs text-red-500">摇摆期客户：所有客户-活跃-流失-无消费</span>
                  </template>

                  <template v-if="formItem.id?.code === 'LSCSTDPTD'">
                    <span class="text-xs text-red-500">无消费客户：没有创建过维修工单的客户</span>
                  </template>
                </en-form-item>
              </en-scrollbar>
            </en-tab-pane>
          </en-tabs>
        </en-form>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
import { uniqBy } from 'lodash-es'

type SystemAttributeDto = EnocloudAdminDefinitions['SystemAttributeDto']

interface AttributeData extends SystemAttributeDto {
  items?: SystemAttributeDto[]
}

export default factory({
  config: {
    children: {
      operation: {
        save: {
          async click() {
            await this.table.submit()
            return this.table.get()
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/branch/attribute',
            data: 'array',
            loading: true
          },
          submit: {
            action: 'PUT /enocloud/admin/branch/attribute',
            loading: true,
            params(params) {
              params.data = this.table.data
            }
          }
        },
        computed: {
          attributeData() {
            return uniqBy(this.table.data, (item) => item.id?.keyType?.code).map((item) => {
              const res: AttributeData = Object.assign({}, item)
              res.items = this.table.data.filter((d) => d.id?.keyType?.code === item.id?.keyType?.code)
              return res
            })
          }
        },
        children: {
          lookup: {
            paths(formItem: SystemAttributeDto) {
              switch (formItem.id?.code) {
                case 'PTSVTPLT':
                  return ['PTSVTPLT']
                case 'PTSVASNT':
                  return ['PTSVASTPLT']
                case 'ISRSTLTMP':
                  return ['ISTMPTYPE']
                case 'PTPUCTPLT':
                case 'PTSALTPLT':
                  return [formItem.id?.code]
                default:
                  return [formItem.id?.valueType]
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    await this.table.get()
    this.tabs.active = this.table.attributeData[0].id?.keyType?.code!
  }
})
</script>
